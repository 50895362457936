import React from 'react';

import { Box, type BoxProps, Pressable, type PressableProps, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

interface PillButtonProps extends PressableProps {
	isSelected?: boolean;
}

const selectedPillButtonStyles = xcss({
	backgroundColor: 'color.background.selected',
	color: 'color.text.selected',
	':hover': {
		backgroundColor: 'color.background.selected.hovered',
	},
	':active': {
		backgroundColor: 'color.background.selected.pressed',
	},
});

const unselectedPillButtonStyles = xcss({
	backgroundColor: 'color.background.neutral',
	color: 'color.text',
	':hover': {
		backgroundColor: 'color.background.neutral.hovered',
	},
	':active': {
		backgroundColor: 'color.background.neutral.pressed',
	},
});

const pillButtonStyles = xcss({
	outline: 'none',
	paddingBlock: 'space.050',
	paddingInline: 'space.200',
	display: 'inline-flex',
	border: 'none',
	borderRadius: 'border.radius.circle',
	fontSize: token('font.body', '14px'),
	lineHeight: '20px',
	height: '28px',
	userSelect: 'none',
	whiteSpace: 'nowrap',
	verticalAlign: 'top',
});

export const PillButton = (props: PillButtonProps) => {
	const { isSelected, ...buttonProps } = props;
	return (
		<Pressable
			{...buttonProps}
			xcss={[pillButtonStyles, isSelected ? selectedPillButtonStyles : unselectedPillButtonStyles]}
		/>
	);
};

const skeletonPillRowStyles = xcss({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
});

export const SkeletonPillRow = (props: BoxProps<'div'>) => {
	return <Box {...props} xcss={skeletonPillRowStyles} />;
};

interface SkeletonPillProps extends BoxProps<'div'> {
	width?: number;
	height?: number;
}

const skeletonPillStyles = xcss({
	display: 'inline-flex',
	backgroundColor: 'color.skeleton',
	borderRadius: 'border.radius.circle',
});

export const SkeletonPill = (props: SkeletonPillProps) => {
	const { width, height, ...divProps } = props;
	return (
		<Box
			{...divProps}
			xcss={skeletonPillStyles}
			style={{
				width: `${width ?? 95}px`,
				height: `${height ?? 28}px`,
			}}
		/>
	);
};
