import { useCallback, useMemo, useEffect } from 'react';
import { useLazyQuery } from 'react-apollo';
import type { ApolloError } from 'apollo-client';

import { traverse } from '@atlaskit/adf-utils/traverse';
import type { ADFEntity } from '@atlaskit/adf-utils/types';

import { ConfluencePageAri } from '@atlassian/ari/confluence/page';

import { useSessionData, useBooleanFeatureFlag } from '@confluence/session-data';
import {
	ContentUnifiedQuery,
	PageUnifiedQueryV2,
	convertToContentUnifiedQueryResult,
	canUsePageUnifiedQueryResult,
	type ContentUnifiedQueryType,
	type ContentUnifiedQueryVariablesType,
	type PageUnifiedQueryV2Type,
	type PageUnifiedQueryV2VariablesType,
	type ContentUnifiedQueryContentNodeType,
} from '@confluence/content-unified-query';
import { useSpaceKey } from '@confluence/space-utils';
import { isUnauthorizedError } from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';

const safeParseADF = (rawADF: string): ADFEntity => {
	try {
		return JSON.parse(rawADF);
	} catch {
		return { type: 'doc' };
	}
};

const mentionedAccountLocalIdMapping: Record<string, string[]> = {};

const parseMentionedAccounts = (pageContentADF: string): Record<string, string> => {
	const parsedADF = safeParseADF(pageContentADF);
	const mentionedAccounts: Record<string, string> = {};

	traverse(parsedADF, {
		mention: (node) => {
			const { id, localId, text = '' } = node.attrs ?? {};
			if (id) {
				mentionedAccounts[id] = text.replace(/^@/, '');
			}
			if (
				localId &&
				(!mentionedAccountLocalIdMapping[id] ||
					!mentionedAccountLocalIdMapping[id].includes(localId))
			) {
				const currentLocalIds = mentionedAccountLocalIdMapping[id] || [];
				// opting not to use spread/concat to prevent recreating array with unknown length
				currentLocalIds.push(localId);
				mentionedAccountLocalIdMapping[id] = currentLocalIds;
			}
		},
	});

	return mentionedAccounts;
};

type UsePageMentionedAccountIdsResult = {
	isLoading: boolean;
	mentionedAccountIds: string[] | null;
	mentionedAccountNames: Record<string, string> | null;
	mentionedAccountLocalIdMapping: Record<string, string[]>;
	loadMentionedAccountIds: () => void;
	lastModifiedDate?: string;
	error: ApolloError | undefined;
};

export const usePageMentionedAccountIdsV2 = (
	contentId: string,
): UsePageMentionedAccountIdsResult => {
	const spaceKey = useSpaceKey();
	const isSpaceAliasFFEnabled = useBooleanFeatureFlag('confluence.frontend.space.alias');
	const isNewContentTopperFFEnabled = useBooleanFeatureFlag(
		'confluence.frontend.custom-sites.page-header-and-title',
	);
	const isSSR = Boolean(process.env.REACT_SSR);

	const isMigratingContentUnifiedQuery =
		useBooleanFeatureFlag('confluence.frontend.content.unified.query.migration') && isSSR;
	const { cloudId } = useSessionData();
	let ari;

	try {
		ari = ConfluencePageAri.create({
			siteId: cloudId,
			pageId: contentId,
		}).toString();
	} catch (e) {
		// Do nothing
	}

	const [
		fetchContentUnifiedADF,
		{ data: contentADFData, loading: contentADFLoading, error: contentADFError },
	] = useLazyQuery<ContentUnifiedQueryType, ContentUnifiedQueryVariablesType>(ContentUnifiedQuery);

	const [fetchPageUnifiedADF, { data: pageADFData, loading: pageADFLoading, error: pageADFError }] =
		useLazyQuery<PageUnifiedQueryV2Type, PageUnifiedQueryV2VariablesType>(PageUnifiedQueryV2, {
			context: { single: true },
		});

	const canUsePageQueryResult = canUsePageUnifiedQueryResult({
		pageUnifiedQueryResult: {
			data: pageADFData,
			loading: pageADFLoading,
			error: pageADFError,
		},
		isMigratingContentUnifiedQuery,
		pageUnifiedQuerySkipped: false,
	});

	useEffect(() => {
		if (pageADFData && !canUsePageQueryResult) {
			fetchContentUnifiedADF({
				variables: {
					contentId,
					spaceKey,
					includeAlias: isSpaceAliasFFEnabled,
					useNewContentTopper: isNewContentTopperFFEnabled,
					versionOverride: null,
				},
			});
		}
	}, [
		pageADFData,
		canUsePageQueryResult,
		fetchContentUnifiedADF,
		contentId,
		spaceKey,
		isSpaceAliasFFEnabled,
		isNewContentTopperFFEnabled,
	]);

	const loadMentionedAccountIds = useCallback(() => {
		if (isMigratingContentUnifiedQuery) {
			fetchPageUnifiedADF({
				variables: {
					contentId: ari,
					includeAlias: isSpaceAliasFFEnabled,
					useNewContentTopper: isNewContentTopperFFEnabled,
				},
			});
		} else {
			fetchContentUnifiedADF({
				variables: {
					contentId,
					spaceKey,
					includeAlias: isSpaceAliasFFEnabled,
					useNewContentTopper: isNewContentTopperFFEnabled,
					versionOverride: null,
				},
			});
		}
	}, [
		isMigratingContentUnifiedQuery,
		fetchPageUnifiedADF,
		ari,
		isSpaceAliasFFEnabled,
		isNewContentTopperFFEnabled,
		fetchContentUnifiedADF,
		contentId,
		spaceKey,
	]);

	let adf;
	let lastModifiedDate;

	const queryData = pageADFData && canUsePageQueryResult ? pageADFData : contentADFData;
	if (queryData) {
		const normalizedResult =
			pageADFData && canUsePageQueryResult
				? convertToContentUnifiedQueryResult(pageADFData)
				: queryData;
		const contentNode = normalizedResult?.content?.nodes?.[0] as ContentUnifiedQueryContentNodeType;

		adf = contentNode?.body?.dynamic?.value;
		lastModifiedDate = contentNode?.metadata?.lastModifiedDate;
	}

	const mentionedAccountNames = useMemo<Record<string, string> | null>(() => {
		if (!pageADFData && !contentADFData) {
			return null;
		}
		if (!adf) {
			return {};
		}
		return parseMentionedAccounts(adf);
	}, [pageADFData, contentADFData, adf]);

	const mentionedAccountIds = useMemo<string[] | null>(() => {
		if (!mentionedAccountNames) {
			return null;
		}
		return Object.keys(mentionedAccountNames);
	}, [mentionedAccountNames]);

	const error = contentADFError || pageADFError;
	if (error) {
		if (!isUnauthorizedError(error)) {
			markErrorAsHandled(contentADFError || pageADFError);
		}
	}

	return {
		isLoading: contentADFLoading || pageADFLoading,
		mentionedAccountIds,
		mentionedAccountNames,
		mentionedAccountLocalIdMapping,
		loadMentionedAccountIds,
		lastModifiedDate,
		error: contentADFError || pageADFError,
	};
};
