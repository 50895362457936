/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { type HTMLProps } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';

import { N10, N500, N800 } from '@atlaskit/theme/colors';
import { fontFamily, fontSizeSmall } from '@atlaskit/theme/constants';
import * as typography from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';

const containerStyles = css({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
});

export const Container = (props: HTMLProps<HTMLDivElement>) => {
	return <div {...props} css={[containerStyles]} />;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const valueTypographyStyles = css(typography.h900());

// No lint errors when composing a css style after the object, however we want this order of precedence
// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const valueStyles = css(valueTypographyStyles, {
	fontWeight: 400,
	display: 'flex',
	alignItems: 'center',
	margin: 0,
	height: '40px',
	color: token('color.text', N800),
});

export const Value = (props: HTMLProps<HTMLLabelElement>) => {
	return <label {...props} css={[valueStyles]} />;
};

const metricStyles = css({
	display: 'flex',
	alignItems: 'center',
	fontFamily: fontFamily(),
	fontSize: `${fontSizeSmall()}px`,
	color: token('color.text', N500),
	lineHeight: '14px',
});

export const Metric = (props: HTMLProps<HTMLLabelElement>) => {
	return <label {...props} css={[metricStyles]} />;
};

interface SkeletonBlockProps extends HTMLProps<HTMLDivElement> {
	height: number;
	marginTop: number;
}
const skeletonBlockStyles = css({
	display: 'flex',
	backgroundColor: token('color.skeleton', N10),
	width: '40px',
	borderRadius: '3px',
	alignItems: 'center',
});

export const SkeletonBlock = (props: SkeletonBlockProps) => {
	const { height, marginTop, ...divProps } = props;
	return (
		<div
			{...divProps}
			css={[skeletonBlockStyles]}
			style={{
				height: `${height}px`,
				marginTop: `${marginTop}px`,
			}}
		/>
	);
};
