import { useCallback, useState } from 'react';

import { SSREvent } from './SSREvent';

export type UseSSROptions = {
	ssrEventCaptureKey: string;
	ssrBestEffortTaskName: string;
};

export type UseSSRResult = {
	client: {
		wasSSRRendered: boolean;
		wasSSRBestEffortSuccessful: boolean;
		onSSRCapturedEvent: (event: SSREvent, callback: () => void) => void;
		wasSSREventTriggered: (event: SSREvent) => boolean;
	};
	server: {
		isRenderingSSR: boolean;
	};
};

export type TriggeredSSRCapturedEvents = { [key in SSREvent]?: boolean };

export const useSSR = ({
	ssrEventCaptureKey,
	ssrBestEffortTaskName,
}: UseSSROptions): UseSSRResult => {
	const isRenderingSSR = !!process.env.REACT_SSR;
	const wasSSRRendered = !!window?.__SSR_RENDERED__;
	const didSSRBestEffortTimeout =
		!!window?.__SSR_QUERY_TIMEOUTS__?.bestEffortTimeouts?.[ssrBestEffortTaskName];
	const wasSSRBestEffortSuccessful = wasSSRRendered && !didSSRBestEffortTimeout;

	const [triggeredSSRCapturedEvents, setTriggeredSSRCapturedEvents] =
		useState<TriggeredSSRCapturedEvents>({});

	const wasSSREventTriggered = useCallback(
		(event: SSREvent) => {
			return !!triggeredSSRCapturedEvents[event];
		},
		[triggeredSSRCapturedEvents],
	);

	const updateSSRCapturedEvent = (event: SSREvent) => {
		switch (event) {
			case SSREvent.CLICK:
				return { click: true };
			case SSREvent.FOCUS:
				return { focus: true };
			case SSREvent.HOVER:
				return { hover: true };
		}
	};

	const wasSSREventCaptured = useCallback(
		(event: SSREvent) => {
			return !!window?.__SSR_EVENTS_CAPTURE__?.[ssrEventCaptureKey]?.[event];
		},
		[ssrEventCaptureKey],
	);

	const onSSRCapturedEvent = useCallback(
		(event: SSREvent, callback: () => void) => {
			if (wasSSREventTriggered(event)) {
				return;
			}
			if (wasSSREventCaptured(event)) {
				callback();
				const updatedEvent = updateSSRCapturedEvent(event);
				setTriggeredSSRCapturedEvents((triggeredSSRCapturedEvents) => ({
					...triggeredSSRCapturedEvents,
					...updatedEvent,
				}));
			}
		},
		[wasSSREventCaptured, wasSSREventTriggered],
	);

	return {
		client: {
			wasSSRRendered,
			wasSSRBestEffortSuccessful,
			onSSRCapturedEvent,
			wasSSREventTriggered,
		},
		server: {
			isRenderingSSR,
		},
	};
};
