import React, { useEffect, useMemo } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import Button from '@atlaskit/button/new';
import { Grid } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { usePageAudience } from '../../../hooks/usePageAudience';
import type { Account } from '../../../hooks';
import { MentionRemindersNudgeWrapper, NudgeLocation } from '../../MentionRemindersNudgeWrapper';

const i18n = defineMessages({
	remindTeammatesButton: {
		id: 'confluence-analytics.mention-reminders.remind.button',
		defaultMessage: 'Send reminders',
		description: 'A label on a button to send a reminder from the Analytics Dialog',
	},
});

interface MentionRemindersFooterProps {
	contentId: string;
	ContentAnalyticsLink: React.ReactElement;
	openMentionRemindersModal: (mentions: Account[]) => void;
}

export const MentionRemindersFooter = ({
	ContentAnalyticsLink,
	openMentionRemindersModal,
	contentId,
}: MentionRemindersFooterProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { mentionsForReminders, mentionedNotViewedCount, loadMentions } = usePageAudience(
		contentId,
		{ mentionsNotViewedCountLimit: 90, mentionsViewedCountLimit: 90, viewersCountLimit: 90 },
	);

	const activeMentionsForReminders = useMemo(
		() =>
			mentionsForReminders.filter(
				(user) => !user.name?.includes('(Deactivated)') && !user.name?.includes('(Unlicensed)'),
			) ?? [],
		[mentionsForReminders],
	);

	const eligibleUsersToRemind =
		Boolean(activeMentionsForReminders.length) && Boolean(mentionedNotViewedCount > 0);

	useEffect(() => {
		loadMentions();
	}, [loadMentions]);

	useEffect(() => {
		if (eligibleUsersToRemind) {
			createAnalyticsEvent({
				type: 'sendScreenEvent',
				data: {
					name: 'mentionsReminderButton',
				},
			}).fire();
		}
	}, [eligibleUsersToRemind, createAnalyticsEvent]);

	return eligibleUsersToRemind ? (
		<Grid
			templateColumns="repeat(auto-fit, minmax(18ch, 1fr))"
			testId="analytics-dialog-footer-button-group"
			gap="space.050"
		>
			{ContentAnalyticsLink}
			<MentionRemindersNudgeWrapper
				nudgeName={NudgeLocation.REMINDER_BUTTON}
				contentId={contentId}
				onConfirm={() => {
					openMentionRemindersModal?.(mentionsForReminders);
				}}
			>
				<Button
					shouldFitContainer
					onClick={() => {
						openMentionRemindersModal?.(mentionsForReminders);
						createAnalyticsEvent({
							type: 'sendUIEvent',
							data: {
								action: 'clicked',
								actionSubject: 'button',
								actionSubjectId: 'mentionsReminderButtonClicked',
								source: 'AnalyticsDialogFooter',
							},
						}).fire();
					}}
				>
					<FormattedMessage {...i18n.remindTeammatesButton} />
				</Button>
			</MentionRemindersNudgeWrapper>
		</Grid>
	) : (
		ContentAnalyticsLink
	);
};
