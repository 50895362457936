import React, { useMemo } from 'react';

import { LinkButton } from '@atlaskit/button/new';

import { useMentionRemindersEligible } from '../../../hooks/useMentionRemindersEligible';

import * as S from './styled';
import { getContentAnalyticsLink } from './getContentAnalyticsLink';

export type ContentAnalyticsLinkProps = React.PropsWithChildren<{
	contentId?: string;
	contentTab?: string;
	contentType?: string;
	testId?: string;
	onClick?: () => void;
}>;

export const ContentAnalyticsLink = ({
	contentId,
	contentTab,
	contentType,
	onClick,
	testId,
	children,
}: ContentAnalyticsLinkProps) => {
	const { isMentionRemindersEligible } = useMentionRemindersEligible(contentId);

	const href = useMemo(() => {
		return getContentAnalyticsLink({ contentTab, contentId, contentType });
	}, [contentTab, contentId, contentType]);

	if (!href) {
		return null;
	}

	if (isMentionRemindersEligible) {
		return (
			<LinkButton shouldFitContainer href={href} onClick={onClick} testId={testId}>
				{children}
			</LinkButton>
		);
	}

	return (
		<S.ContentAnalyticsLinkButton
			href={href}
			onClick={onClick}
			testId={testId}
			shouldFitContainer={false}
			appearance="link"
			hoverTextDecoration="underline"
		>
			{children}
		</S.ContentAnalyticsLinkButton>
	);
};
