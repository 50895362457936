import React from 'react';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl-next';

import InfoPanel from '@atlassian/analytics-info-panel';

import { TestId } from '../constants';

import * as S from './styled';

export type AnalyticsDialogInfoPanelProps = {
	isLoading: boolean;
	viewsCount: number;
	uniqueViewsCount: number;
};

export const AnalyticsDialogInfoPanel: FC<AnalyticsDialogInfoPanelProps> = ({
	isLoading,
	viewsCount,
	uniqueViewsCount,
}) => (
	<S.InfoPanelRow>
		<S.InfoPanelContainer>
			<InfoPanel
				value={viewsCount}
				metric={
					<FormattedMessage
						id="confluence-analytics.metrics.views-all-time"
						defaultMessage="views (all-time)"
						description="Name of a metric."
					/>
				}
				isLoading={isLoading}
				testId={TestId.INFO_PANEL_1}
			/>
		</S.InfoPanelContainer>
		<S.InfoPanelContainer>
			<InfoPanel
				value={uniqueViewsCount}
				metric={
					<FormattedMessage
						id="confluence-analytics.metrics.unique-viewers-all-time"
						defaultMessage="unique viewers (all-time)"
						description="Name of a metric."
					/>
				}
				isLoading={isLoading}
				testId={TestId.INFO_PANEL_2}
			/>
		</S.InfoPanelContainer>
	</S.InfoPanelRow>
);
