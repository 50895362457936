import { useCallback, useEffect, useMemo } from 'react';
import { useLazyQuery } from 'react-apollo';
import type { ApolloError } from 'apollo-client';

import { traverse } from '@atlaskit/adf-utils/traverse';
import type { ADFEntity } from '@atlaskit/adf-utils/types';

import { ContentToADFQuery } from '../queries/ContentToADFQuery.graphql';
import type {
	ContentToADFQuery as ContentToADFQueryResponse,
	ContentToADFQueryVariables,
} from '../queries/__types__/ContentToADFQuery';

const safeParseADF = (rawADF: string): ADFEntity => {
	try {
		return JSON.parse(rawADF);
	} catch {
		return { type: 'doc' };
	}
};

const mentionedAccountLocalIdMapping: Record<string, string[]> = {};

const parseMentionedAccounts = (pageContentADF: string): Record<string, string> => {
	const parsedADF = safeParseADF(pageContentADF);
	const mentionedAccounts: Record<string, string> = {};

	traverse(parsedADF, {
		mention: (node) => {
			const { id, localId, text = '' } = node.attrs ?? {};
			if (id) {
				mentionedAccounts[id] = text.replace(/^@/, '');
			}
			if (
				localId &&
				(!mentionedAccountLocalIdMapping[id] ||
					!mentionedAccountLocalIdMapping[id].includes(localId))
			) {
				const currentLocalIds = mentionedAccountLocalIdMapping[id] || [];
				// opting not to use spread/concat to prevent recreating array with unknown length
				currentLocalIds.push(localId);
				mentionedAccountLocalIdMapping[id] = currentLocalIds;
			}
		},
	});

	return mentionedAccounts;
};

export type UsePageMentionedAccountIdsResult = {
	isLoading: boolean;
	mentionedAccountIds: string[] | null;
	mentionedAccountNames: Record<string, string> | null;
	mentionedAccountLocalIdMapping: Record<string, string[]>;
	loadMentionedAccountIds: () => void;
	error: ApolloError | undefined;
};

export const usePageMentionedAccountIds = (
	contentId: string,
	skip?: boolean,
): UsePageMentionedAccountIdsResult => {
	const [fetchADF, { data, loading: isLoading, error }] = useLazyQuery<
		ContentToADFQueryResponse,
		ContentToADFQueryVariables
	>(ContentToADFQuery, {
		variables: { contentId },
	});

	useEffect(() => {
		if (!skip) {
			fetchADF({ variables: { contentId } });
		}
	}, [fetchADF, contentId, skip]);

	const mentionedAccountNames = useMemo<Record<string, string> | null>(() => {
		if (!data) {
			return null;
		}
		const adf = data?.content?.nodes?.[0]?.body?.atlas_doc_format?.value;
		if (!adf) {
			return {};
		}
		return parseMentionedAccounts(adf);
	}, [data]);

	const mentionedAccountIds = useMemo<string[] | null>(() => {
		if (!mentionedAccountNames) {
			return null;
		}
		return Object.keys(mentionedAccountNames);
	}, [mentionedAccountNames]);

	const loadMentionedAccountIds = useCallback(() => {
		fetchADF({ variables: { contentId } });
	}, [contentId, fetchADF]);

	return {
		isLoading,
		mentionedAccountIds,
		mentionedAccountNames,
		mentionedAccountLocalIdMapping,
		loadMentionedAccountIds,
		error,
	};
};
