import { styled } from '@compiled/react';
import React, { useCallback, useContext } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import type { ButtonProps } from '@atlaskit/button/standard-button';
import Button from '@atlaskit/button/standard-button';
import GraphLineIcon from '@atlaskit/icon/glyph/graph-line';
import { N200 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import FeatureGates from '@atlaskit/feature-gate-js-client';
import { xcss, Box } from '@atlaskit/primitives';

import { ChangeEditionSectionMessagePageInsights } from '@confluence/change-edition/entry-points/pageInsights';
import {
	ANALYTICS_DIALOG_UPSELL_EXPERIENCE,
	ExperienceSuccess,
} from '@confluence/experience-tracker';
import { SPAViewContext } from '@confluence/spa-view-context';
import {
	showNotTellFeatureGates,
	useShowNotTellFGABExperiment,
} from '@confluence/experiment-show-not-tell-feature-gates/entry-points';

import { SSREvent } from '../../hooks';

import type { UseAnalyticsByLineHook } from './UseAnalyticsByLineHook';

const standardWrapperStyles = xcss({
	width: '350px',
});

const popUpShowNotTellExperimentWrapperStyles = xcss({
	width: '446px',
});

const popupContentExperience = ANALYTICS_DIALOG_UPSELL_EXPERIENCE;

export const useAnalyticsByLineUpsell: UseAnalyticsByLineHook = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { isSiteAdmin } = useContext(SPAViewContext);
	// TODO: clean up ticket in https://atl-growth.atlassian.net/browse/NOTELL-56
	const { renderTreatment } = useShowNotTellFGABExperiment({
		isExperimentEnabled: Boolean(FeatureGates.checkGate(showNotTellFeatureGates.STATSIG_SNT_FG_AB)),
		touchpointId: 'pageInsights',
		skipExposureEvents: true,
	});
	const onPopupTriggerSSREvent = useCallback(
		(event: SSREvent) => {
			if (event === SSREvent.CLICK) {
				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						action: 'clicked',
						actionSubject: 'button',
						actionSubjectId: 'pageInsightGate',
						source: 'pageInsights',
						attributes: {
							isSiteAdmin,
						},
					},
				}).fire();
			}
		},
		[createAnalyticsEvent, isSiteAdmin],
	);

	return {
		loading: false,

		//
		// Popup content
		//

		popupContent: () => (
			<Box
				as="section"
				xcss={renderTreatment ? popUpShowNotTellExperimentWrapperStyles : standardWrapperStyles}
			>
				<ChangeEditionSectionMessagePageInsights>
					<ExperienceSuccess name={popupContentExperience} />
				</ChangeEditionSectionMessagePageInsights>
			</Box>
		),

		popupContentExperience,

		//
		// Popup trigger
		//

		popupTrigger: ({ onClick }) => <PopupTrigger onClick={onClick} />,

		onPopupTriggerSSREvent,
	};
};

const i18n = defineMessages({
	byLineUpsellText: {
		id: 'confluence-analytics.byLineUpSell.buttonText',
		defaultMessage: 'See how many people viewed this page',
		description: 'Analytics button text which would show how many people viewed a page',
	},
});

// Below Icon and Button styles are copied from @atlassian/analytics-byline to keep the styles consistent

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const GraphIconWrapper = styled.span({
	marginRight: token('space.050', '4px'),
	verticalAlign: 'middle',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ByLineButtonWrapper = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:before': {
		content: '"•"',
		display: 'inline-block',
		color: token('color.text.subtle', N200),
		paddingRight: token('space.100', '8px'),
		/* for IE <= 11 */
		textDecoration: 'none',
	},
});

type PopupTriggerProps = Pick<ButtonProps, 'onClick'>;

const PopupTrigger = (props: PopupTriggerProps) => (
	<ByLineButtonWrapper>
		<Button
			{...props}
			appearance="subtle-link"
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={{ fontWeight: 'unset' }}
			spacing="none"
		>
			<GraphIconWrapper aria-hidden="true">
				<GraphLineIcon label="" size="small" />
			</GraphIconWrapper>
			<FormattedMessage {...i18n.byLineUpsellText} />
		</Button>
	</ByLineButtonWrapper>
);
