import React, { type FC } from 'react';

import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';

import Button from '@atlaskit/button/standard-button';
import GraphLineIcon from '@atlaskit/icon/glyph/graph-line';

import * as S from './styled';

const i18n = defineMessages({
	viewers: {
		id: 'confluence-analytics-byline.user-views.message',
		defaultMessage:
			'{count, plural, =0 {Analytics} one {1 person viewed} other {{count} people viewed}}',
		description: 'Analytics byline text to represent the count of unique viewers for the page.',
	},
});

export type AnalyticsBylineProps = {
	count: number;
	isBylineComponent?: boolean;
	onClick?: (e?: React.MouseEvent<Element>) => void;
	href?: string;
	testId?: string;
};

const handleOnClick =
	(onClick?: (e?: React.MouseEvent<Element>) => void) =>
	(e?: React.MouseEvent<Element>): void => {
		if (onClick) {
			return onClick(e);
		}
		return undefined;
	};

const formatAbbreviatedNumber = (locale: string, count: number): string => {
	try {
		const numberFormatter = Intl.NumberFormat(locale, {
			notation: 'compact',
			compactDisplay: 'short',
		});
		return numberFormatter.format(count).toLocaleLowerCase();
	} catch (e) {
		return String(count);
	}
};

const AnalyticsByline: FC<AnalyticsBylineProps> = ({
	isBylineComponent,
	onClick,
	href,
	count,
	testId,
}) => {
	const intl = useIntl();
	const value = formatAbbreviatedNumber(intl.locale, count);

	return (
		<S.AnalyticsBylineWrapper isBylineComponent={isBylineComponent} data-testid={testId}>
			<Button
				href={href}
				onClick={handleOnClick(onClick)}
				appearance="subtle-link"
				spacing="none"
				// TODO: (from codemod) Buttons with "component", "css" or "style" prop can't be automatically migrated with codemods. Please migrate it manually.
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				style={{ fontWeight: 'unset' }}
			>
				<S.AnalyticsIconWrapper>
					<GraphLineIcon label="" size="small" />
				</S.AnalyticsIconWrapper>
				<FormattedMessage {...i18n.viewers} values={{ count: value }} />
			</Button>
		</S.AnalyticsBylineWrapper>
	);
};

export default AnalyticsByline;
