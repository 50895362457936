import gql from 'graphql-tag';

export const ContentToADFQuery = gql`
	query ContentToADFQuery($contentId: ID!) {
		content(id: $contentId) {
			nodes {
				id
				type
				body {
					atlas_doc_format {
						value
					}
				}
			}
		}
	}
`;
