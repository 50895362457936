import React from 'react';
import type { FC } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import { useMentionRemindersEligible } from '../../../hooks/useMentionRemindersEligible';
import type { Account } from '../../../hooks';
import { ContentAnalyticsLink } from '../ContentAnalyticsLink';
import { TestId, DialogPillName } from '../constants';
import type { DialogPillToPanelMapping } from '../constants';
import { AnalyticsDialogFooterSkeleton } from '../LoadingSkeleton';

import { MentionRemindersFooter } from './MentionRemindersFooter';
import * as S from './styled';

export type AnalyticsDialogFooterProps = {
	isLoading: boolean;
	contentId: string;
	contentTab?: string;
	contentType?: string;
	onClickAnalyticsLink?: () => void;
	selectedPill: DialogPillName;
	openMentionRemindersModal: (mentions: Account[]) => void;
};

const i18n = defineMessages({
	viewMoreInsightsButton: {
		id: 'confluence-analytics.view-more-insights.button',
		defaultMessage: 'View more insights',
		description: 'A label on a button to view more analytics.',
	},
	morePageAnalyticsButton: {
		id: 'confluence-analytics.more-page-insights.button',
		defaultMessage: 'More page analytics',
		description: 'A label on a button to see more page analytics.',
	},
	remindTeammatesButton: {
		id: 'confluence-analytics.mention-reminders.remind.button',
		defaultMessage: 'Remind teammates',
		description: 'A label on a button to send a reminder',
	},
});

export const AnalyticsDialogFooter: FC<AnalyticsDialogFooterProps> = ({
	isLoading,
	contentId,
	contentTab,
	contentType,
	onClickAnalyticsLink,
	selectedPill,
	openMentionRemindersModal,
}) => {
	const { isMentionRemindersEligible } = useMentionRemindersEligible(contentId);

	const dialogFooter: DialogPillToPanelMapping = {
		[DialogPillName.VIEWS]: isMentionRemindersEligible ? (
			<ContentAnalyticsLink
				contentId={contentId}
				contentTab={contentTab}
				contentType={contentType}
				testId={TestId.LINK}
				onClick={onClickAnalyticsLink}
			>
				<FormattedMessage {...i18n.morePageAnalyticsButton} />
			</ContentAnalyticsLink>
		) : (
			<S.FooterContainer>
				<ContentAnalyticsLink
					contentId={contentId}
					contentTab={contentTab}
					contentType={contentType}
					testId={TestId.LINK}
					onClick={onClickAnalyticsLink}
				>
					<FormattedMessage {...i18n.viewMoreInsightsButton} />
				</ContentAnalyticsLink>
			</S.FooterContainer>
		),
		[DialogPillName.MENTIONS]: isMentionRemindersEligible ? (
			<MentionRemindersFooter
				openMentionRemindersModal={openMentionRemindersModal}
				contentId={contentId}
				ContentAnalyticsLink={
					<ContentAnalyticsLink
						contentId={contentId}
						contentTab={contentTab}
						contentType={contentType}
						testId={TestId.LINK}
						onClick={onClickAnalyticsLink}
					>
						<FormattedMessage {...i18n.morePageAnalyticsButton} />
					</ContentAnalyticsLink>
				}
			/>
		) : (
			<S.FooterContainer>
				<ContentAnalyticsLink
					contentId={contentId}
					contentTab={contentTab}
					contentType={contentType}
					testId={TestId.LINK}
					onClick={onClickAnalyticsLink}
				>
					<FormattedMessage {...i18n.viewMoreInsightsButton} />
				</ContentAnalyticsLink>
			</S.FooterContainer>
		),
	};

	return (
		<>
			{isLoading ? (
				<AnalyticsDialogFooterSkeleton data-testid={TestId.LINK_SKELETON} />
			) : (
				dialogFooter[selectedPill]
			)}
		</>
	);
};
