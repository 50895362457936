import React from 'react';
import { useIntl } from 'react-intl-next';

import type { Account } from '../../../hooks';
import { AccountType } from '../../../hooks';
import { TestId } from '../constants';

import * as S from './styled';
import { AnalyticsDialogUserListRow } from './UserListRow';

const loadingStateRowCount = 5;

export type AnalyticsDialogUserListProps = {
	isLoading: boolean;
	accounts: Account[];
	ariaLabel?: string;
};

export const AnalyticsDialogUserList = ({
	isLoading,
	accounts,
	ariaLabel,
}: AnalyticsDialogUserListProps) => {
	const intl = useIntl();

	return (
		<S.UserListContainer
			role={isLoading ? 'alert' : undefined}
			aria-busy={isLoading}
			aria-live="polite"
			aria-label={
				isLoading
					? intl.formatMessage({
							id: 'confluence-analytics.dialog.user-list.loading-label',
							defaultMessage: 'Loading',
							description:
								'Text read by screen readers when a user list for the analytics dialog is loading.',
						})
					: ariaLabel
			}
			data-testid={TestId.USER_LIST}
		>
			{isLoading
				? Array(loadingStateRowCount)
						.fill(null)
						.map((_, index) => <AnalyticsDialogUserListRow key={`loading-${index}`} isLoading />)
				: accounts.map((account, index) => (
						<AnalyticsDialogUserListRow
							key={
								account.accountType === AccountType.ANONYMOUS ? `anonymous-${index}` : account.id
							}
							isLoading={false}
							account={account}
						/>
					))}
		</S.UserListContainer>
	);
};
