import {
	CONTENT_ANALYTICS_VIEWERS_SEGMENT_KEY,
	createInteractionMetric,
	createPageSegmentLoadMetric,
	CONTENT_ANALYTICS_DIALOG_INTERACTION,
	CONTENT_ANALYTICS_DIALOG_MENTIONS_TAB_INTERACTION,
} from '@confluence/browser-metrics';
export const CONTENT_ANALYTICS_VIEWERS_METRIC = createPageSegmentLoadMetric({
	key: CONTENT_ANALYTICS_VIEWERS_SEGMENT_KEY,
});

export const CONTENT_ANALYTICS_DIALOG_METRIC = createInteractionMetric({
	key: CONTENT_ANALYTICS_DIALOG_INTERACTION,
});

export const CONTENT_ANALYTICS_DIALOG_MENTIONS_TAB_METRIC = createInteractionMetric({
	key: CONTENT_ANALYTICS_DIALOG_MENTIONS_TAB_INTERACTION,
});
