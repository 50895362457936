import React from 'react';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl-next';

import { Box, xcss } from '@atlaskit/primitives';

import { useSessionData } from '@confluence/session-data';

import { TestId, pillToLoadingTextI18n, DialogPillName } from './constants';
import * as S from './styled';
import { AnalyticsDialogHeader } from './Header';
import { AnalyticsDialogInfoPanel } from './InfoPanel';
import { AnalyticsDialogFooter } from './Footer';
import { AnalyticsDialogUserList } from './UserList';

type AnalyticsDialogSsrProps = {
	zIndex: number;
};

const marginBottomStyles = xcss({ marginBottom: 'space.200' });

export const AnalyticsDialogSsr: FC<AnalyticsDialogSsrProps> = ({ zIndex }) => {
	const { edition } = useSessionData();
	const shouldShowMentions = edition === 'PREMIUM';

	return (
		<S.SSRContainer zIndex={zIndex} data-testid={TestId.DIALOG_SKELETON}>
			<S.Container role="dialog">
				<AnalyticsDialogHeader
					isLoading
					shouldShowPills={shouldShowMentions}
					selectedPill={DialogPillName.VIEWS}
				/>
				<AnalyticsDialogInfoPanel isLoading viewsCount={0} uniqueViewsCount={0} />
				<Box xcss={marginBottomStyles}>
					<S.SSRChartSkeleton />
				</Box>
				<S.SSRLoadingText>
					<FormattedMessage {...pillToLoadingTextI18n[DialogPillName.VIEWS]} />
				</S.SSRLoadingText>
				{shouldShowMentions && (
					<Box xcss={marginBottomStyles}>
						<AnalyticsDialogUserList accounts={[]} isLoading />
					</Box>
				)}
				<AnalyticsDialogFooter
					isLoading
					contentId=""
					contentType=""
					selectedPill={DialogPillName.VIEWS}
					openMentionRemindersModal={() => {}}
				/>
			</S.Container>
		</S.SSRContainer>
	);
};
